import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import {
    ContactMain,
    SectionTitle,
    ContactSection,
    ContactInput,
    ContactTextArea,
    Subheader,
    ContactActionButton,
    ContactButton,
} from "./styled";
import { S3Key } from "../utils";
import * as emailjs from "emailjs-com";

export const Contact = () => {
    const history = useHistory();

    const [view, updateView] = useState("message");
    const [message, updateMessage] = useState("");
    const [name, updateName] = useState("");
    const [email, updateEmail] = useState("");
    const [phone, updatePhone] = useState("");
    const [company, updateCompany] = useState("");

    const onNext = () => {
        if (message) {
            updateView("details");
        }
    };

    const onSend = (e) => {
        e.preventDefault();

        emailjs
            .send(
                "service_j7kico5",
                "template_b1q6n3p",
                {
                    from_name: name,
                    phone: phone,
                    email: email,
                    company: company,
                    message: message,
                },
                "user_vVhoquD33ItbUJ9GdUw3D"
            )
            .then((result) => history.push("/"));
    };

    return (
        <ContactMain>
            <ContactButton src={S3Key + "home.png"} alt="/" onClick={() => history.push("/")} />
            <ContactSection>
                <SectionTitle>Contact Us</SectionTitle>
                {view === "message" && (
                    <>
                        <Subheader style={{ marginLeft: "11px", marginTop: "20px" }}>Hello Arcturus,</Subheader>
                        <ContactTextArea
                            placeholder="Type Your Message Here"
                            bordered={false}
                            autoSize={{ minRows: 1, maxRows: 8 }}
                            value={message}
                            onChange={(e) => updateMessage(e.target.value)}
                            autoFocus
                        />
                        <ContactActionButton onClick={(e) => onNext()}>next</ContactActionButton>
                    </>
                )}
                {view === "details" && message.length > 0 && (
                    <>
                        <ContactInput
                            placeholder="Type Your Name Here"
                            bordered={false}
                            autoFocus
                            value={name}
                            onChange={(e) => updateName(e.target.value)}
                        />
                        <ContactInput
                            placeholder="Type Your Email Here"
                            bordered={false}
                            value={email}
                            onChange={(e) => updateEmail(e.target.value)}
                        />
                        <ContactInput
                            placeholder="Type Your Phone Number Here (Optional)"
                            bordered={false}
                            value={phone}
                            onChange={(e) => updatePhone(e.target.value)}
                        />
                        <ContactInput
                            placeholder="Type Your Company Name Here (Optional)"
                            bordered={false}
                            value={company}
                            onChange={(e) => updateCompany(e.target.value)}
                        />
                        <ContactActionButton onClick={(e) => onSend(e)}>send</ContactActionButton>
                    </>
                )}
            </ContactSection>
        </ContactMain>
    );
};
