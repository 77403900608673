import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from "./home";
import { Contact } from "./contact";
import { Enter } from "./enter";

export const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={() => <Enter />} />
                <Route path="/home" exact component={() => <Home />} />
                <Route path="/contact" exact component={() => <Contact />} />
            </Switch>
        </Router>
    );
};
