import React, {useState, useEffect, useMemo, useRef} from "react";
import Globe from "react-globe.gl";
import * as d3 from "d3";
import styled, { keyframes } from "styled-components";
import { HeroSubheader, Text } from "./styled";
import ScrollAnimation from "react-animate-on-scroll";
import Typist from "react-typist";
import { brightBlue, breakpoint } from "../utils";
import { isMobile } from "react-device-detect";

export const Map = () => {
    const [countries, setCountries] = useState({ features: [] });
    const [hoverD, setHoverD] = useState();
    const [clicked, setClicked] = useState();

    console.log(hoverD && hoverD.properties ? hoverD.properties.NAME : null);

    useEffect(() => {
        // load data
        fetch("arcturus.geojson", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setCountries(myJson);
            });
    }, []);

    const colorScale = d3.scaleSequentialSqrt(d3.interpolateYlOrRd);

    // GDP per capita (avoiding countries with small pop)
    const getVal = (feat) => feat.properties.GDP_MD_EST / Math.max(1e5, feat.properties.POP_EST);

    const maxVal = useMemo(() => Math.max(...countries.features.map(getVal)), [countries]);
    colorScale.domain([0, maxVal]);

    const globeGL = useRef(null);

    return (
        <>
            <GlobeContainer
                isHover={clicked}
                // onAnimationEnd={() => setClicked(null)}
                className={clicked && !isMobile ? "move-left" : "move-right"}
            >
                <Globe
                    ref={globeGL}
                    globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                    enableZoom={false}
                    onGlobeReady={() => {
                            globeGL.current.pointOfView({
                                lat: -0.35195672462370453,
                                lng: 13.248180568601967,
                                altitude: 2.227470248991142
                            })
                    }}
                    backgroundColor="rgba(0, 0, 0, 0)"
                    lineHoverPrecision={0}
                    // polygonsData={countries.features.filter((d) => d.properties.ISO_A2 !== "AQ" && d.properties.ISO_A2 !== "US")}
                    polygonAltitude={(d) => (d === hoverD ? 0.04 : 0.01)}
                    polygonCapColor={(d) => (d === hoverD ? "rgba(255, 255, 255, 0.5)" : "rgba(255, 255, 255, 0.2)")}
                    polygonSideColor={() => "rgba(255, 255, 255, 0.2)"}
                    polygonStrokeColor={() => "rgba(255, 255, 255, 0.2)"}
                    polygonLabel={({ properties: d }) => `
                <b>${d.ADMIN} (${d.ISO_A2})</b><br />Click for more
              `}
                    // onPolygonHover={setHoverD}
                    // onPolygonClick={setClicked}
                    polygonsTransitionDuration={300}
                />
            </GlobeContainer>
            {clicked && clicked.properties && clicked.properties.NAME === "South Africa" && (
                <InfoBox>
                    <Typist avgTypingDelay={15} stdTypingDelay={7}>
                        <Subheader>Network</Subheader>
                        <EntryText>8.5 million sources covering urban, suburban and rural communities</EntryText>
                        <br />
                        <Subheader>What We Look For</Subheader>
                        <EntryText>
                            Violent robberies, community violence, high-value theft, residential crime and
                            demonstrations
                        </EntryText>
                        <br />
                        <Subheader>How We Can Help</Subheader>
                        <EntryText>
                            Prevent cash-in-transit robberies, reduce theft and property damage, secure out-of-office
                            assets, anticipate commercial disruptions
                        </EntryText>
                    </Typist>
                </InfoBox>
            )}
            {clicked && clicked.properties && clicked.properties.NAME === "Nigeria" && (
                <InfoBox>
                    <Typist avgTypingDelay={15} stdTypingDelay={7}>
                        <Subheader>Network</Subheader>
                        <EntryText>
                            Thousands of reports a week coupled with strategically placed sources across business, civil
                            society and government
                        </EntryText>
                        <br />
                        <Subheader>What We Look For</Subheader>
                        <EntryText>
                            Organized malicious actors (north and south), non-government checkpoints, theft on roads
                        </EntryText>
                        <br />
                        <Subheader>How We Can Help</Subheader>
                        <EntryText>
                            Slash last-mile distribution risks, secure hospitality operations, safely invest in high-cap
                            projects
                        </EntryText>
                    </Typist>
                </InfoBox>
            )}
            {clicked && clicked.properties && clicked.properties.NAME === "Kenya" && (
                <InfoBox>
                    <Typist avgTypingDelay={15} stdTypingDelay={7}>
                        <Subheader>Network</Subheader>
                        <EntryText>
                            Fully customized data aggregation channels with access to a wide range of public sector and
                            academic partners
                        </EntryText>
                        <br />
                        <Subheader>What We Look For</Subheader>
                        <EntryText>
                            Organized malicious actors (particularly in the east), major threats, violent crimes
                        </EntryText>
                        <br />
                        <Subheader>How We Can Help</Subheader>
                        <EntryText>
                            Monitor cross-border militant activity, secure large workforces, reduce stock theft and
                            property damage
                        </EntryText>
                    </Typist>
                </InfoBox>
            )}
        </>
    );
};

const moveLeft = keyframes`
from { left: 0; }
    to   { left: -25vw; }
`;

const GlobeContainer = styled.div`
    position: fixed;
    top: 0;
    transition: all 0.3s ease-out;
`;

const fadein = keyframes`
from { opacity: 0; }
    to   { opacity: 1; }
`;

const InfoBox = styled.div`
    height: 70vh;
    width: 80vw;
    position: absolute;
    top: 10vh;
    right: 10vw;

    @media (min-width: ${breakpoint}) {
        width: 35vw;
        top: 15vh;
    }
`;

const Subheader = styled(HeroSubheader)`
    font-style: normal;
    margin-bottom: 5px;
    color: ${brightBlue};
`;

const EntryText = styled(Text)`
    font-family: "IBM Plex Mono", monospace;
    font-size: 14px;

    @media (min-width: ${breakpoint}) {
        font-size: 18px;
    }
`;
