import styled, { keyframes } from "styled-components";
import { S3Key, breakpoint, brightBlue } from "../utils";
import { Collapse, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Player } from "video-react";
const { Panel } = Collapse;

const fadein = keyframes`
from { opacity: 0; }
    to   { opacity: 1; }
`;

export const Main = styled.div`
    padding: 0;
    margin: 0;
    min-height: 100vh;
    z-index: 1;
    position: absolute;
    background: linear-gradient(#000000, #3e3e3e);

    @media (min-width: ${breakpoint}) {
    }
`;

export const ContactMain = styled.div`
    background: linear-gradient(#000000, #3e3e3e);
    padding: 50px 0;
    height: 100vh;
    width: 100vw;

    @media (min-width: ${breakpoint}) {
        padding: 100px 0;
    }
`;

export const ContactButton = styled.img`
    height: 24px;
    position: fixed;
    top: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 1000;

    @media (min-width: ${breakpoint}) {
        top: 50px;
        right: 50px;
    }
`;

export const FullscreenSection = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 50%;
    text-align: center;
`;

export const TopSection = styled.div`
    height: 50px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 50%;
    text-align: center;
`;

export const Section = styled.div`
    padding: 100px 0;
    width: 80%;
    margin-top: 10vw;
    margin-left: 10%;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${breakpoint}) {
        width: 50%;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ContactSection = styled.div`
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;

    @media (min-width: ${breakpoint}) {
        width: 75%;
    }
`;

export const LogoContainer = styled.img`
    position: absolute;
    top: 20px;
    left: 5vw;
    width: 60vw;

    @media (min-width: ${breakpoint}) {
        top: 50px;
        left: 50px;
        width: 350px;
    }
`;

export const HeroSubheader = styled.div`
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 24px;

    @media (min-width: ${breakpoint}) {
        font-size: 30px;
        width: 700px;
    }
`;

export const SectionTitle = styled.div`
    font-family: "Poppins", sans-serif;
    color: ${(props) => (props.styling === "contact" ? "white" : brightBlue)};
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 36px;

    @media (min-width: ${breakpoint}) {
        font-size: 68px;
    }
`;

export const Text = styled.div`
    font-family: "IBM Plex Sans", sans-serif;
    color: white;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 16px;

    @media (min-width: ${breakpoint}) {
        font-size: 20px;
    }
`;

export const Expand = styled(Collapse)`
    color: white;
    margin-top: 30px;

    @media (min-width: ${breakpoint}) {
        margin-top: 50px;
    }
`;

export const ExpandItem = styled(Panel)`
    .ant-collapse-content {
        padding-bottom: 32px;
    }
`;

export const ExpandIcon = styled.img`
    width: 24px;
    margin-right: 10px;
    margin-left: ${(props) => (props.styling === "careers" ? "10px" : "0px")};

    @media (min-width: ${breakpoint}) {
        width: 34px;
        margin-right: 30px;
        margin-left: ${(props) => (props.styling === "careers" ? "30px" : "0px")};
    }
`;

export const Subheader = styled.div`
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;

    @media (min-width: ${breakpoint}) {
        font-size: 28px;
        line-height: 1.3em;
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ContactInput = styled(Input)`
    margin: 10px 0;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 20px;

    @media (min-width: ${breakpoint}) {
        margin: 30px 0;
        font-size: 28px;
    }
`;

export const ContactTextArea = styled(TextArea)`
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 1px;
    font-size: 20px;

    @media (min-width: ${breakpoint}) {
        font-size: 28px;
    }
`;

export const ContactActionButton = styled(Subheader)`
    position: fixed;
    bottom: 20px;
    right: 10vw;
    color: ${brightBlue};
    text-transform: uppercase;
    font-style: normal;
    cursor: pointer;

    @media (min-width: ${breakpoint}) {
        bottom: 100px;
        right: 200px;
    }
`;

export const ContentContainer = styled.div`
    width: 100%;

    @media (min-width: ${breakpoint}) {
        width: 50%;
    }
`;

export const EnterButton = styled.button`
    width: 230px;
    position: absolute;
    bottom: 100px;
    left: calc(50% - 115px);
    color: rgba(255, 255, 255, 0.7);
    background: #4b4baf;
    border-radius: 25px;
    outline: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 2.7em;
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    font-size: 16px;

    @media (min-width: ${breakpoint}) {
        bottom: 50px;

        &:hover {
            width: 240px;
            left: calc(50% - 120px);
            line-height: 3em;
            bottom: 47px;
            color: rgba(255, 255, 255, 1);
            background: rgba(255, 255, 255, 0.2);
        }
    }
`;

export const OvalButton = styled.button`
    width: 260px;
    color: rgba(255, 255, 255, 1);
    background: #4b4baf;
    border-radius: 25px;
    outline: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 2.7em;
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 10px;

    &:hover {
        width: 270px;
        line-height: 3em;
        color: rgba(255, 255, 255, 1);
        margin-top: 37px;
        margin-bottom: 8px;
    }
`;

export const UseCaseContainer = styled.div`
    margin-bottom: 100px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (min-width: ${breakpoint}) {
        width: 90%;
        margin-left: ${(props) => (props.flipped ? "0" : "10%")};
        flex-direction: row;
        height: 80vh;
    }
`;

export const UseCaseDescription = styled.div`
    width: 95%;
    margin-left: 3%;

    @media (min-width: ${breakpoint}) {
        width: 30%;
        margin-right: ${(props) => (props.flipped ? "0" : "10%")};
        margin-left: ${(props) => (props.flipped ? "10%" : "0")};
    }
`;

export const UseCaseMedia = styled.div`
    width: 95%;
    margin-left: 3%;
    overflow: hidden;
    border-radius: 8px;

    @media (min-width: ${breakpoint}) {
        width: 60%;
        margin-left: 0;
        direction: ${(props) => (props.flipped ? "rtl" : "initial")};
    }
`;

export const UseCaseMediaImage = styled.img`
    overflow: hidden;
    border-radius: 8px;
    height: 200px;
    @media (min-width: ${breakpoint}) {
        height: 600px;
        border-radius: 15px;
    }
`;

export const Video = styled(Player)`
    border-radius: 8px;
    :focus {
        outline: none !important;
    }
`;

export const BackgroundVideo = styled(Player)`
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    z-index: 1;
    border-radius: 0px;

    :focus {
        outline: none !important;
    }
`;

export const VideoContainer = styled.div`
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
`;

export const Darkener = styled.div`
    filter: brightness(30%);
`;

export const VideoOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 71vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 50%;
    text-align: center;
    z-index: 100;
`;
