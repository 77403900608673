import React, { useState, useEffect, useMemo } from "react";
import Globe from "react-globe.gl";
import * as d3 from "d3";
import styled from "styled-components";
import { S3Key } from "../utils";
import { Map } from "./map";
import { HeroSubheader, Text, EnterButton } from "./styled";
import ScrollAnimation from "react-animate-on-scroll";
import { isMobile } from "react-device-detect";
import { breakpoint } from "../utils";

export const Enter = () => {
    return (
        <>
            <Map />
            <Logo src={S3Key + "arcturus-white.png"} />
            <a href="/">
                <MapMessage>
                Arcturus is a research firm specializing in on-the-ground collection in emerging markets.
                </MapMessage>
                <MapMessage2>
                </MapMessage2>
                {/* <EnterButton type="button">Learn More</EnterButton> */}
                <ContactButton type="button">
                    <a href="/contact">Contact us</a>
                </ContactButton>
            </a>
            <a href="https://angel.co/company/arcturus-1" target="_blank">
                <MapMessage2>
                    We're hiring.
                </MapMessage2>
            </a>
        </>
    );
};

const ContactButton = styled.button`
    width: 300px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    background: none;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    line-height: 3em;
    font-family: "IBM Plex Sans", sans-serif;
    letter-spacing: 1px;
    font-size: 14px;
`;

const Logo = styled.img`
    position: absolute;
    top: 20px;
    left: 5vw;
    width: 60vw;

    @media (min-width: ${breakpoint}) {
        width: 350px;
        top: 50px;
        left: 50px;
    }
`;

const MapMessage = styled.div`
    position: absolute;
    width: 640px;
    top: calc((100vh - 200px) / 2);
    left: calc(50% - 320px);
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    font-size: 24px;
    text-align: center;
    font-weight: 300;
    font-style: normal;
`;

const MapMessage2 = styled.div`
    position: absolute;
    width: 520px;
    top: calc((100vh + 200px) / 2);
    left: calc(50% - 260px);
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    font-size: 24px;
    text-align: center;
    font-weight: 300;
    font-style: normal;
    text-decoration: underline;
`;
