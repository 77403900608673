import React from "react";
import { S3Key, breakpoint, brightBlue } from "../utils";
import {
    Main,
    ContactButton,
    FlexContainer,
    ExpandIcon,
    Expand,
    ExpandItem,
    Text,
    HeroSubheader,
    Subheader,
    Section,
    SectionTitle,
    LogoContainer,
    FullscreenSection,
    ContentContainer,
    OvalButton,
    UseCaseContainer,
    UseCaseDescription,
    UseCaseMedia,
    UseCaseMediaImage,
    Video,
    BackgroundVideo,
    TopSection,
    VideoOverlay,
    VideoContainer,
    Darkener,
} from "./styled";
import { useHistory } from "react-router-dom";
import { Map } from "./map";
import styled from "styled-components";
import ScrollAnimation from "react-animate-on-scroll";
import "video-react/dist/video-react.css";
import { ControlBar } from "video-react";
import { isMobile } from "react-device-detect";

export const Home = () => {
    const history = useHistory();

    const getExpansionHeader = (type) => {
        if (type === "expand") {
            return (
                <FlexContainer>
                    <ExpandIcon src={S3Key + "expand.png"} alt="expand" />
                    <Subheader>Expand With Confidence</Subheader>
                </FlexContainer>
            );
        } else if (type === "prevent") {
            return (
                <FlexContainer>
                    <ExpandIcon src={S3Key + "prevent.png"} alt="prevent" />
                    <Subheader>Prevent Losses</Subheader>
                </FlexContainer>
            );
        } else if (type === "protect") {
            return (
                <FlexContainer>
                    <ExpandIcon src={S3Key + "protect.png"} alt="protect" />
                    <Subheader>Protect Critical Assets</Subheader>
                </FlexContainer>
            );
        } else if (type === "safeguard") {
            return (
                <FlexContainer>
                    <ExpandIcon src={S3Key + "safeguard.png"} alt="safeguard" />
                    <Subheader>Safeguard Your Reputation</Subheader>
                </FlexContainer>
            );
        }
    };

    return (
        <>
            <Main>
                <ContentContainer className="fade-in">
                    <VideoContainer>
                        {!isMobile && (
                            <Darkener>
                                <BackgroundVideo
                                    fluid={true}
                                    muted
                                    aspectRatio={"16:9"}
                                    autoPlay={true}
                                    loop={true}
                                    src="https://orion-assets-s3.s3.us-east-2.amazonaws.com/Website+Loop+Draft.mp4"
                                >
                                    <ControlBar autoHide={true} disableCompletely={true} />
                                </BackgroundVideo>
                            </Darkener>
                        )}
                        <ContactButton
                            src={S3Key + "contact.png"}
                            alt="contact"
                            onClick={() => history.push("/contact")}
                        />
                        <LogoContainer src={S3Key + "arcturus-white.png"} alt="arcturus-logo" />
                        <VideoOverlay>
                            <HeroSubheader style={{ marginTop: isMobile ? "70vh" : "0" }}>
                                Tailored, actionable business risk intelligence for corporate decision makers working in
                                emerging markets.
                            </HeroSubheader>
                            <Text style={{ marginTop: "20px", color: "rgba(255, 255, 255, 0.7)" }}>
                                {/* <br />
                                <br />
                                We deliver these insights through our software platform, Orion. Designed by intelligence
                                veterans and powered by a patent-pending artificial intelligence, Orion empowers the world’s
                                leading organizations to expand and operate with confidence in emerging markets by
                                discovering and forecasting risks to their most important assets.
                                <br />
                                <br /> */}
                                Currently in the Republic of South Africa, Nigeria and Kenya.
                            </Text>
                            <OvalButton
                                type="button"
                                onClick={() =>
                                    document
                                        .getElementById("platform")
                                        .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
                                }
                            >
                                About Our Platform
                            </OvalButton>
                        </VideoOverlay>
                    </VideoContainer>
                </ContentContainer>
                <Section id="platform">
                    <SectionTitle>Platform</SectionTitle>
                    <Text>
                        Our software platform, Orion, is designed by intelligence veterans and powered by a
                        patent-pending artificial intelligence. Orion empowers the world’s leading organizations to
                        expand and operate with confidence in emerging markets by discovering and forecasting risks to
                        their most important assets.
                    </Text>
                </Section>
                <UseCaseContainer>
                    <UseCaseDescription>
                        <Subheader>Risk Analytics</Subheader>
                        <br />
                        <Text>See the real-time breakdown of recent incidents in your area and over time.</Text>
                        <br />
                    </UseCaseDescription>
                    <UseCaseMedia>
                        <Video
                            fluid={false}
                            playsInline
                            height={isMobile ? 200 : 600}
                            muted
                            autoPlay
                            loop={true}
                            src="https://idylworld-static.sgp1.digitaloceanspaces.com/risk-analytics.mov"
                        >
                            <ControlBar autoHide={true} disableCompletely={true} />
                        </Video>
                    </UseCaseMedia>
                </UseCaseContainer>
                <UseCaseContainer flipped={true}>
                    {isMobile && (
                        <UseCaseDescription flipped={true}>
                            <Subheader>Data Visualization</Subheader>
                            <br />
                            <Text>
                                Alerts to crime and other social disturbances in your area, shown in aggregate from a
                                single pane of glass.
                            </Text>
                            <br />
                        </UseCaseDescription>
                    )}
                    <UseCaseMedia flipped={true}>
                        <UseCaseMediaImage
                            src="https://idylworld-static.sgp1.digitaloceanspaces.com/data-visualization.png"
                            alt="risk-forecasting"
                            flipped={true}
                        />
                    </UseCaseMedia>
                    {!isMobile && (
                        <UseCaseDescription flipped={true}>
                            <Subheader>Data Visualization</Subheader>
                            <br />
                            <Text>
                                Alerts to crime and other social disturbances in your area, shown in aggregate from a
                                single pane of glass.
                            </Text>
                            <br />
                        </UseCaseDescription>
                    )}
                </UseCaseContainer>
                <UseCaseContainer>
                    <UseCaseDescription>
                        <Subheader>Risk Forecasting</Subheader>
                        <br />
                        <Text>Predict risks to your organization with 90% accuracy, seven days in advance.</Text>
                        <br />
                    </UseCaseDescription>
                    <UseCaseMedia>
                        <UseCaseMediaImage
                            src="https://idylworld-static.sgp1.digitaloceanspaces.com/risk-forecasting.png"
                            alt="risk-forecasting"
                        />
                    </UseCaseMedia>
                </UseCaseContainer>
                {/* <UseCaseContainer>
                    <UseCaseDescription>
                        <Subheader>Map Filtering allows you to blah blah blah blah blah blah blah blah blah.</Subheader>
                        <br />
                        <Text>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </Text>
                        <br />
                    </UseCaseDescription>
                    <UseCaseMedia>
                        <UseCaseMediaImage
                            src="https://idylworld-static.sgp1.digitaloceanspaces.com/map-filtering.png"
                            alt="risk-forecasting"
                        />
                    </UseCaseMedia>
                </UseCaseContainer> */}
                {/* <Section>
                    <SectionTitle>Platform</SectionTitle>
                    <Text>
                        We empower our partners to operate with confidence in emerging markets. Orion tailors itself to
                        your organization's footprint across Sub-Saharan Africa, and discovers the physical risks facing
                        the things you care about most, such as your property, operations and employees. Orion then
                        provides decision makers with a clear picture of how these risks stand to affect their
                        profitability, reputation, and chances of project success.
                    </Text>
                    <Expand defaultActiveKey={["1"]} ghost expandIconPosition="right" accordion={true}>
                        <ExpandItem header={getExpansionHeader("expand")} key="1" showArrow={false}>
                            <Text>
                                Orion provides the data surrounding crime, conflict and social disturbances required to
                                expand corporate activity in Sub-Saharan Africa. With Orion, our partners can take
                                advantage of opportunities unavailable to the rest of the market.
                                <br />
                                <br />
                                As our enterprise partners expand, we grow with them. From market entry to in-country
                                and cross-border expansion, we illuminate the risks that accompany new, increasingly
                                complex operations and identify obstacles to profitability and project success.
                                <br />
                                <br />
                                We also partner with best-in-class institutional credit and equity investors to screen
                                potential opportunities and conduct due diligence. Whether spotting arbitrage scenarios
                                or evaluating the resilience of a prospective investment, Arcturus provides independent,
                                objective, data-driven insights to inform our partners’ most pressing business
                                decisions.
                            </Text>
                        </ExpandItem>
                        <ExpandItem header={getExpansionHeader("prevent")} key="2" showArrow={false}>
                            <Text>
                                Orion’s AI engine discovers patterns among millions of data points to predict where and
                                when your stock, equipment, and other valuable assets are most likely to be in jeopardy.
                                This means our clients can take action to prevent serious losses days before they occur.
                            </Text>
                        </ExpandItem>
                        <ExpandItem header={getExpansionHeader("protect")} key="3" showArrow={false}>
                            <Text>
                                Orion maps our partners’ unique asset footprint across Sub-Saharan Africa. By taking
                                into account asset type, identifying markings, pre-existing safety measures and more,
                                the platform is able to identify the most vulnerable parts of our partners’
                                organizations and predict how they are most likely to come under threat. This insight
                                empowers our users to effectively deploy countermeasures days in advance of a potential
                                threat, thereby shielding critical assets, data and staff from harm while maximizing
                                operational uptime.
                            </Text>
                        </ExpandItem>
                        <ExpandItem header={getExpansionHeader("safeguard")} key="4" showArrow={false}>
                            <Text>
                                While emerging markets offer billions of dollars in opportunity, they also pose
                                potential reputational hazards — namely, projects often fail due to unforeseen risks,
                                and employees can be put in harm’s way by insufficient planning and information. With
                                Orion, our partners can ensure that they meet client expectations, keep their employees
                                safe, and slash liability across their operations in Sub-Saharan Africa.
                            </Text>
                        </ExpandItem>
                    </Expand>
                </Section> */}
                {/* <Section>
                    <SectionTitle>Ideas That Inspire Us</SectionTitle>
                    <Text>
                        To Kill a Mockingbird — Harper Lee <br />
                        1984 — George Orwell <br />
                        The Catcher in the Rye — J.D. Salinger <br />
                        Lord of the Flies — William Golding <br />
                        Slaughterhouse-Five — Kurt Vonnegut Jr. <br />
                        A Clockwork Orange — Anthony Burgess <br />
                        All the King's Men — Robert Penn Warren <br />
                        The Blind Assassin — Margaret Atwood <br />
                    </Text>
                </Section> */}
                <Section>
                    <FlexContainer>
                        <SectionTitle>Careers</SectionTitle>
                        <ExpandIcon src={S3Key + "careers.png"} alt="careers" styling="careers" />
                    </FlexContainer>
                    <Text>
                        Join a diverse team of professionals dedicated to unlocking emerging markets. If you’re
                        passionate about cutting-edge technologies, building something new, or international business,
                        please reach out regarding one of the listed positions{" "}
                        <a href="https://angel.co/company/arcturus-1/jobs">here</a>.
                        <br />
                        <br />
                        You can also get in touch with us through <a href="/contact">our Contact page</a>; we’re always
                        looking for exceptional people, regardless of professional background.
                    </Text>
                </Section>
            </Main>
        </>
    );
};
